<template>
  <div>
    <button
      class="button my-3"
      :class="{'button_loading': updateAdminsLoading}"
      @click="updateAdmins">
      {{$t('channel.admins.actions.update')}}
    </button>
    <blinkable ref="adminsBlink">
      <ul class="channel-admins__admins-list">
        <li v-for="admin in admins">{{getUserString(admin)}}</li>
      </ul>
    </blinkable>
  </div>
</template>

<script>
  import Blinkable from '@/components/Blinkable.vue'

  import {
    getUserString,
  } from '@/functions/user'

  import {
    apiUpdateAdmins,
  } from '@/functions/api'

  export default {
    name: 'ChannelAdmins',
    components: {
      Blinkable,
    },
    props: {
      channel: Object,
    },
    data() {
      return {
        admins: this.channel.admins,

        updateAdminsLoading: false,
      }
    },
    methods: {
      getUserString,

      async updateAdmins() {
        this.updateAdminsLoading = true
        const result = await apiUpdateAdmins({cid: this.channel.cid})
        this.updateAdminsLoading = false

        if (result.ok) {
          this.admins = result.result.admins
          return this.$refs.adminsBlink.blink()
        }
        return this.$refs.adminsBlink.blink('blink-error')
      },
    },
  }
</script>

<style scoped lang="stylus">
  .channel-admins__admins-list
    li
      @apply px-4 py-3
      @apply border-t border-gray-300
</style>